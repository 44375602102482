/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
import { SYS_ACCESS, SYS_ACCESS_ROLE_MAP } from './constants';

// 判断是否有权限
function hasAccess(currentUser: any, access: SYS_ACCESS) {
  return SYS_ACCESS_ROLE_MAP[access].includes(currentUser?.role);
}

export default function access(initialState: { currentUser?: any } | undefined) {
  const { currentUser } = initialState ?? {};
  return {
    canDashBoard: hasAccess(currentUser, SYS_ACCESS.DASHBOARD),
    canScreening: hasAccess(currentUser, SYS_ACCESS.SCREENING),
    canAnalytics: hasAccess(currentUser, SYS_ACCESS.ANALYTICS),
    canReport: hasAccess(currentUser, SYS_ACCESS.REPORT),
    canStudentView: hasAccess(currentUser, SYS_ACCESS.STUDENT_VIEW),
    canStudentManage: hasAccess(currentUser, SYS_ACCESS.STUDENT_MANAGE),
    canScoreView: hasAccess(currentUser, SYS_ACCESS.SCORE_VIEW),
    canScoreManage: hasAccess(currentUser, SYS_ACCESS.SCORE_MANAGE),
    canCommunicationView: hasAccess(currentUser, SYS_ACCESS.COMMUNICATION_VIEW),
    canCommunicationManage: hasAccess(currentUser, SYS_ACCESS.COMMUNICATION_MANAGE),
    canAssmtUserView: hasAccess(currentUser, SYS_ACCESS.ASSMT_USER_VIEW),
    canManageSysUser: hasAccess(currentUser, SYS_ACCESS.SYSTEM_MEMBER_MANAGE),
  };
}
