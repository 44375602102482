import { request } from '@umijs/max';

// 获取当前登录用户信息
export function getCurrentUser(options?: Record<string, any>) {
  return request('/api/system/current_user', {
    method: 'GET',
    ...(options || {}),
  });
}

// 获取当前用户菜单
export function getCurrentUserMenus() {
  return request(`/api/system/current_user_menus`, {
    method: 'GET',
  });
}

// 获取图片验证码
export function getCaptcha() {
  return request('/api/system/captcha', {
    method: 'GET',
  });
}

// 系统登录
export function login(body: any, options?: Record<string, any>) {
  return request('/api/system/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

// 查询系统用户列表
export async function getSystemUsers(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: Record<string, any>,
) {
  return request('/api/system/users', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

// 创建系统用户
export function addSystemUser(data?: Record<string, any>) {
  return request('/api/system/users', {
    method: 'POST',
    data,
  });
}

// 更新系统用户信息
export function updateSystemUser(sysUserId: ID, data?: Record<string, any>) {
  return request(`/api/system/users/${sysUserId}`, {
    method: 'PUT',
    data,
  });
}

// 禁用/启用系统用户
export function toggleSystemUserStatus(sysUserId: ID, data?: Record<string, any>) {
  return request(`/api/system/users/${sysUserId}/status`, {
    method: 'PUT',
    data,
  });
}

// 更新系统用户密码
export function updatePwd(data?: Record<string, any>) {
  return request('/api/system/update_pwd', {
    method: 'POST',
    data,
  });
}

// 创建角色
export async function createRole(data?: Record<string, any>) {
  return request('/api/system/roles', {
    method: 'POST',
    data,
  });
}

// 获取角色列表
export async function findAllRoles(
  params?: {
    current?: number;
    pageSize?: number;
  },
  options?: Record<string, any>,
) {
  return request('/api/system/roles', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

// 查找角色
export async function findOneRole(roleId: ID, options?: Record<string, any>) {
  return request(`/api/system/roles/${roleId}`, {
    method: 'GET',
    ...(options || {}),
  });
}

// 更新角色
export function updateRole(roleId: ID, data?: Record<string, any>) {
  return request(`/api/system/roles/${roleId}`, {
    method: 'PUT',
    data,
  });
}

// 删除角色
export function removeRole(roleId: ID) {
  return request(`/api/system/roles/${roleId}`, {
    method: 'DELETE',
  });
}

// 查询角色权限
export function findRolePermissions(roleId: ID) {
  return request(`/api/system/roles/${roleId}/permissions`, {
    method: 'GET',
  });
}

// 为角色分配权限
export function assignRolePermissions(roleId: ID, data?: Record<string, any>) {
  return request(`/api/system/roles/${roleId}/permissions`, {
    method: 'POST',
    data: data?.permissions,
  });
}

// 查询角色菜单
export function findRoleMenus(roleId: ID) {
  return request(`/api/system/roles/${roleId}/menus`, {
    method: 'GET',
  });
}

// 为角色分配菜单
export function assignRoleMenus(roleId: ID, data?: Record<string, any>) {
  return request(`/api/system/roles/${roleId}/menus`, {
    method: 'POST',
    data: data?.menus,
  });
}

// 创建菜单
export async function createMenus(data?: Record<string, any>) {
  return request('/api/system/menus', {
    method: 'POST',
    data,
  });
}

// 查询菜单列表
export async function findAllMenus(options?: Record<string, any>) {
  return request('/api/system/menus', {
    method: 'GET',
    ...(options || {}),
  });
}

// 查询菜单详情
export async function findOneMenu(menuId: ID, options?: Record<string, any>) {
  return request(`/api/system/menus/${menuId}`, {
    method: 'GET',
    ...(options || {}),
  });
}

// 修改菜单
export async function updateMenu(menuId: ID, data?: Record<string, any>) {
  return request(`/api/system/menus/${menuId}`, {
    method: 'PUT',
    data,
  });
}

// 删除菜单
export async function removeMenu(menuId: ID) {
  return request(`/api/system/menus/${menuId}`, {
    method: 'DELETE',
  });
}

// 查询菜单权限
export async function findMenuPermissions(menuId: ID) {
  return request(`/api/system/menus/${menuId}/permissions`);
}

// 为菜单分配权限
export async function assignMenuPermissions(menuId: ID, data?: Record<string, any>) {
  return request(`/api/system/menus/${menuId}/permissions`, {
    method: 'POST',
    data: data?.permissions,
  });
}

// 创建权限
export async function createPermissions(data?: Record<string, any>) {
  return request('/api/system/permissions', {
    method: 'POST',
    data,
  });
}

// 获取权限列表
export async function findAllPermissions(
  params?: {
    current?: number;
    pageSize?: number;
  },
  options?: Record<string, any>,
) {
  return request('/api/system/permissions', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

// 查找权限
export async function findOnePermission(permId: ID, options?: Record<string, any>) {
  return request(`/api/system/permissions/${permId}`, {
    method: 'GET',
    ...(options || {}),
  });
}

// 更新权限
export function updatePermission(permId: ID, data?: Record<string, any>) {
  return request(`/api/system/permissions/${permId}`, {
    method: 'PUT',
    data,
  });
}

// 删除权限
export function removePermission(permId: ID) {
  return request(`/api/system/permissions/${permId}`, {
    method: 'DELETE',
  });
}
