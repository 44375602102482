type GetIdFunc<T> = (item: T) => string;
type GetParentIdFunc<T> = (item: T) => number | string | null | undefined;
type MapperFunc<T> = (item: T) => TreeNode<T>;

interface TreeNode<T> {
  value: string;
  title: string;
  key: string;
  name?: string;
  children?: TreeNode<T>[];
  data?: T;
}

function isEmpty(value: any): boolean {
  return value === null || value === undefined || value === '';
}

/**
 * 构建树形结构
 * @param items 数据列表
 * @param getId 获取数据项的唯一标识
 * @param getParentId 获取数据项的父级标识
 * @param mapperFn 数据项转换函数
 * @returns 树形结构
 */
export function buildTree<T>(
  items: T[],
  getId: GetIdFunc<T>,
  getParentId: GetParentIdFunc<T>,
  mapperFn: MapperFunc<T>,
): TreeNode<T>[] {
  const tree: TreeNode<T>[] = [];
  const itemMap = new Map<string, TreeNode<T>>();
  const visited = new Set<string>();

  items.forEach((item) => {
    const id = getId(item);
    const treeNode = mapperFn(item);
    itemMap.set(id, treeNode);
  });

  items.forEach((item) => {
    const id = getId(item);
    const parentId = getParentId(item);
    const treeNode = itemMap.get(id);

    // 检查是否已经处理过该节点,即是否有链式引用
    if (visited.has(id)) {
      console.warn(`Duplicate processing detected for id: ${id}. Possible circular reference.`);
      return;
    }

    visited.add(id);

    if (!treeNode) {
      return;
    }

    if (isEmpty(parentId) || Number(parentId) === 0) {
      tree.push(treeNode);
    } else {
      const parentNode = itemMap.get(String(parentId));
      if (parentNode) {
        if (!parentNode.children) {
          parentNode.children = [];
        }
        parentNode.children.push(treeNode);
      }
    }
  });

  return tree;
}

export function buildPathTree<T>(
  data: T[],
  getPath: (item: T) => string,
  separator: string,
): TreeNode<T>[] {
  const root: TreeNode<T>[] = [];

  for (const item of data) {
    const path = getPath(item);
    const parts = path.split(separator);
    let currentLevel = root;

    parts.forEach((part, index) => {
      let node = currentLevel.find((node) => node.name === part);
      if (!node) {
        node = { name: part, value: '', title: part, key: '', children: [] };
        currentLevel.push(node);
      }

      if (index === parts.length - 1) {
        node.data = item;
      }

      currentLevel = node.children!;
    });
  }

  return root;
}
