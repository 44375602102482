import { ProLayoutProps } from '@ant-design/pro-components';

/**
 * @name
 */
const Settings: ProLayoutProps & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  // 拂晓蓝
  // colorPrimary: '#1890ff',
  colorPrimary: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  // title: 'WALNUT',
  title: '行舟校园心理健康管理系统',
  pwa: true,
  logo: '/logo@3x.png',
  iconfontUrl: '',
  token: {
    // 参见ts声明，demo 见文档，通过token 修改样式
    //https://procomponents.ant.design/components/layout#%E9%80%9A%E8%BF%87-token-%E4%BF%AE%E6%94%B9%E6%A0%B7%E5%BC%8F

    // 侧边栏
    sider: {
      colorMenuBackground: 'transparent',
    },
    header:{
      colorBgHeader: 'rgba(240, 242, 245, 0.4)',
    }
  },
};

export default Settings;
