// 抑郁测评ID
export const SCREENING_DEPRESSION_ASSESSMENT_ID = '10008';
export const SCREENING_DEPRESSION_ASSESSMENT_SCALE_ID = '10028';

// 焦虑测评ID
export const SCREENING_ANXIETY_ASSESSMENT_ID = '10009';
export const SCREENING_ANXIETY_ASSESSMENT_SCALE_ID = '10029';

// 系统角色
export enum SYS_ROLE {
  SUPER_ADMIN = '0', // 超级管理员
  ORG_ADMIN = '1', // 机构管理员
  SCHOOL_ADMIN = '2', // 学校管理员
  OPERATOR_SCORE = '3', // 操作岗位(学生成绩录入)
  OPERATOR_COMMUNICATION = '4', // 操作岗位(学生心理沟通记录)
  SUPER_ANALIST = '5', // 超级分析师
}

// 系统角色名称
export const ROLE_NAME_MAP = {
  [SYS_ROLE.SUPER_ADMIN]: '超级管理员',
  [SYS_ROLE.ORG_ADMIN]: '机构管理员',
  [SYS_ROLE.SCHOOL_ADMIN]: '学校管理员',
  [SYS_ROLE.OPERATOR_SCORE]: '操作岗位(学生成绩录入)',
  [SYS_ROLE.OPERATOR_COMMUNICATION]: '操作岗位(学生心理沟通记录)',
  [SYS_ROLE.SUPER_ANALIST]: '超级分析师',
};

// 系统权限
export enum SYS_ACCESS {
  // 概览
  DASHBOARD = 'dashboard',
  // 筛查
  SCREENING = 'screening_manage',
  // 测评分析
  ANALYTICS = 'assessment_analytics',
  // 测评报告
  REPORT = 'assessment_report',
  // 学生查看
  STUDENT_VIEW = 'student_view',
  // 学生管理
  STUDENT_MANAGE = 'student_manage',
  // 成绩查看
  SCORE_VIEW = 'score_view',
  // 成绩管理
  SCORE_MANAGE = 'score_manage',
  // 心理沟通查看
  COMMUNICATION_VIEW = 'communication_view',
  // 心理沟通管理
  COMMUNICATION_MANAGE = 'communication_manage',
  // 测评用户查看
  ASSMT_USER_VIEW = 'assmt_user_view',
  // 系统成员管理
  SYSTEM_MEMBER_MANAGE = 'system_member_manage',
}

// 角色权限表
export const SYS_ACCESS_ROLE_MAP: { [k in SYS_ACCESS]: SYS_ROLE[] } = {
  [SYS_ACCESS.DASHBOARD]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.ORG_ADMIN,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_SCORE,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.SCREENING]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.ORG_ADMIN,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.ANALYTICS]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.ORG_ADMIN,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.REPORT]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.ORG_ADMIN,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.STUDENT_VIEW]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.ORG_ADMIN,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_SCORE,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.STUDENT_MANAGE]: [SYS_ROLE.OPERATOR_SCORE, SYS_ROLE.OPERATOR_COMMUNICATION],
  [SYS_ACCESS.SCORE_VIEW]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.ORG_ADMIN,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_SCORE,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.SCORE_MANAGE]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.OPERATOR_SCORE,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.COMMUNICATION_VIEW]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.ORG_ADMIN,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],
  [SYS_ACCESS.COMMUNICATION_MANAGE]: [SYS_ROLE.SUPER_ADMIN, SYS_ROLE.OPERATOR_COMMUNICATION],
  [SYS_ACCESS.ASSMT_USER_VIEW]: [
    SYS_ROLE.SUPER_ADMIN,
    SYS_ROLE.SUPER_ANALIST,
    SYS_ROLE.SCHOOL_ADMIN,
    SYS_ROLE.OPERATOR_COMMUNICATION,
  ],

  [SYS_ACCESS.SYSTEM_MEMBER_MANAGE]: [SYS_ROLE.SUPER_ADMIN, SYS_ROLE.ORG_ADMIN],
};
