// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from '/Users/ray/Work/Workspace/walnut-analytics/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import PieChartOutlined from '/Users/ray/Work/Workspace/walnut-analytics/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/PieChartOutlined';
import RadarChartOutlined from '/Users/ray/Work/Workspace/walnut-analytics/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/RadarChartOutlined';
import BarChartOutlined from '/Users/ray/Work/Workspace/walnut-analytics/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/BarChartOutlined';
import SolutionOutlined from '/Users/ray/Work/Workspace/walnut-analytics/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import TeamOutlined from '/Users/ray/Work/Workspace/walnut-analytics/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import SettingOutlined from '/Users/ray/Work/Workspace/walnut-analytics/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SettingOutlined';
export default { HomeOutlined, PieChartOutlined, RadarChartOutlined, BarChartOutlined, SolutionOutlined, TeamOutlined, SettingOutlined };
